<template>
  <div class="login-container">
    <div class="platform">
      <div class="container-bg">
        <p>
          <span class="ball1"></span>
        </p>
        <p>
          <span class="ball2"></span>
        </p>
        <p>
          <span class="ball3"></span>
        </p>
      </div>
      <div class="platform-container">
        <div class="header">
          <div class="center">
            <section>
              <img :src="loginImg" alt srcset />
            </section>
            <div class="section">
              <h3 style="letter-spacing: 4px">{{ $configs.projectText }}</h3>
              <p>{{ $configs.projectEngText }}</p>
            </div>
          </div>
        </div>
        <div class="main">
          <el-form
            ref="ruleForm"
            v-show="!changeStatus"
            :model="ruleForm"
            :rules="rules"
            label-position="left"
            class="demo-ruleForm login-page demo-form-inline"
          >
            <el-form-item label="登录" class="loginMsg"></el-form-item>
            <el-form-item prop="userName">
              <el-input
                v-model.trim="ruleForm.userName"
                type="text"
                auto-complete="off"
                placeholder="用户名"
              >
                <i slot="prefix" class="iconfont its_zhanghao"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model.trim="ruleForm.password"
                type="password"
                auto-complete="off"
                placeholder="密码"
              >
                <i slot="prefix" class="iconfont its_mima"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="checked" class="rememberme"
                >记住密码</el-checkbox
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                :loading="logining"
                @click="handleSubmit"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
          <!-- 修改密码 -->
          <el-form
            v-show="changeStatus"
            :model="changeForm"
            :rules="rules2"
            ref="changeForm"
            label-position="left"
            class="demo-ruleForm login-page demo-form-inline"
          >
            <el-form-item label="修改密码" class="loginMsg"></el-form-item>
            <el-form-item prop="oldPassword">
              <el-input
                type="password"
                v-model.trim="changeForm.oldPassword"
                autocomplete="off"
                placeholder="请输入旧密码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                v-model.trim="changeForm.password"
                autocomplete="off"
                placeholder="请输入新密码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
              <el-input
                type="password"
                v-model.trim="changeForm.checkPass"
                autocomplete="off"
                placeholder="请再次确认密码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="changePassWord"
                :loading="logining"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="footer">
          <div>{{ $configs.companyText }}</div>
          <div @click="toBeianMiit">
            {{ $configs.filingsText }}
          </div>
          <div @click="toBeianMiit2">川公网安备51080002000345号</div>
        </div>
      </div>
    </div>
    <div v-if="loadingShow" class="loading">
      <div id="load">
        <div>G</div>
        <div>N</div>
        <div>I</div>
        <div>D</div>
        <div>A</div>
        <div>O</div>
        <div>L</div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, logins, updatePassword } from "@/api/lib/api.js";
import md5 from "js-md5";
// import { menuRecursion } from "@/common/utils/index";
import { checkPassword } from '@/common/utils/index'
export default {
  data () {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.changeForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validatePassRepeat = (rule, value, callback) => {
      if (value === this.changeForm.oldPassword) {
        callback(new Error('新密码不能等于旧密码!'))
      } else {
        callback()
      }
    }
    return {
      logining: false,
      checked: false,
      yhsdp: null,
      loading: false,
      changeStatus: false,//修改密码的状态：登陆成功超过90天没有修改密码，则改变页面进行修改
      ruleForm: {
        userName: "",
        password: "",
      },
      changeForm: {
        oldPassword: '',
        password: '',
        checkPass: ''
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      rules2: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' },
          { validator: validatePassRepeat, trigger: 'blur' }
        ],
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'change' }
        ],
        checkPass: [
          { required: true, message: '请输入确认密码', trigger: 'change' },
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      popDoms: null,
      loadingShow: false,
      loginImg: require(`@/assets/images/home/${this.$configs.logoImg}`),
      remindNotify: null,
    };
  },
  methods: {
    toBeianMiit () {
      window.open("https://beian.miit.gov.cn/");
    },
    toBeianMiit2 () {
      window.open("https://beian.mps.gov.cn/#/query/webSearch");
    },
    routerForFun (tree) {
      let type;
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].type == 1) {
          type = i;
          break;
        }
      }
      return type;
    },
    handleSubmit (event) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.logining = true;
          const password = md5(this.ruleForm.password);
          const regEn = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+'\-={}:"<>?,.\/]).{8,16}$/;
          login({ loginName: this.ruleForm.userName, password: password })
            .then((res) => {
              if (res.code === "SUCCESS") {
                sessionStorage.setItem("token", res.data.token);
                //等保
                const { updatePasswrod, locking } = res.data;
                if (locking) {
                  console.log('是否被锁定locking')
                  this.logining = false
                  this.$message.warning('密码错误登录超过5次，账号已被锁定！10分钟后自动解锁')
                  return
                }
                if (updatePasswrod) {
                  console.log('判断超过90天没有修改密码updatePasswrod')
                  this.logining = false
                  this.changeStatus = true;
                  this.changeForm = {
                    oldPassword: '',
                    password: '',
                    checkPass: ''
                  }
                  this.$message.warning('密码超过90天未修改，请修改密码')
                  return
                }
                //判断复杂度
                if (!regEn.test(this.ruleForm.password)) {
                  console.log('修改密码')
                  this.logining = false
                  this.changeStatus = true;
                  this.changeForm = {
                    oldPassword: '',
                    password: '',
                    checkPass: ''
                  }
                  this.$message.warning('密码太简单，请修改密码')
                  return
                }

                sessionStorage.setItem("username", this.ruleForm.userName);
                sessionStorage.setItem("realname", res.data.userName);
                sessionStorage.setItem("userId", res.data.id);
                sessionStorage.setItem("phone", res.data.phone);
                sessionStorage.setItem("companyId", res.data.companyIds);
                var tree = res.data.menuTree;
                let index = tree.subMenuTrees.findIndex(
                  (e) => e.url == "gyTravel"
                );
                if (index >= 0) tree.subMenuTrees.splice(index, 1);
                sessionStorage.setItem("menuList", JSON.stringify(tree));
                this.$store.dispatch("addUserInfo", res.data);
                this.$store.dispatch("add_Menus", res.data.menuTree);
                this.$message.success("登录成功");
                //登录后判断是否需要提示用户更新版本
                this.remindUpdate(res.data.version);
                // 实例化websocket
                this.$socket.init(res.data.token);
                this.$router.push(
                  "/projectTravel/" +
                  tree.subMenuTrees[this.routerForFun(tree.subMenuTrees)]
                    .subMenuTrees[0].url
                );
                // this.getUserLnlat(res.data.companyIdsList)
              } else {
                this.$message.error(res.msg);
              }
              this.logining = false;
            })
            .catch((error) => {
              setTimeout(() => {
                this.logining = false;
              }, 1000);
            });
          if (this.checked) {
            // 保存用户名和密码
            localStorage.setItem(
              "userPwd",
              JSON.stringify({
                name: this.ruleForm.userName,
                pwd: this.ruleForm.password,
              })
            );
          } else {
            localStorage.removeItem("userPwd");
          }
        } else {
          return false;
        }
      });
    },
    remindUpdate (vision) {
      if (vision != this.$configs.vision && !localStorage.getItem("remindUpdate")) {
        this.remindNotify = this.$notify({
          title: "提示",
          message: "系统有更新，请按‘Ctrl+F5’强制刷新界面获取最新版本。点击右上角...查看更新日志",
          type: "success",
          duration: 0,
          onClick: this.onClickRemindNotify,
          onClose: this.onCloseRemindNotify
        });
      }
    },
    onClickRemindNotify () {
      localStorage.setItem("remindUpdate", true);
      this.remindNotify.close();
      location.reload();
    },
    onCloseRemindNotify () {
      localStorage.setItem("remindUpdate", true);
      location.reload();
    },
    changePassWord () {
      this.$refs.changeForm.validate((valid) => {
        if (valid) {
          this.logining = true;
          const data = {
            password: md5(this.changeForm.oldPassword),
            newPassword: md5(this.changeForm.password),
            checkPassword: md5(this.changeForm.checkPass),
          }
          updatePassword(data).then((res) => {
            if (res.code === 'SUCCESS') {
              this.$message.success('修改成功')
              this.changeStatus = false;
              this.ruleForm = {
                userName: '',
                password: ''
              }
              sessionStorage.removeItem('token')
            } else {
              this.$message.error('修改失败')
            }
            this.logining = false;
          }).catch(err => {
            this.logining = false;
          })
        } else {
          return false
        }
      })
    },
  },
  created () {
    var lett = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        lett.handleSubmit();
      }
    };
    localStorage.removeItem("vuex");
    this.$store.dispatch("clear_all");
    this.$store.dispatch("clear_MenuCtr");
    this.$store.dispatch("clear_user");
    this.$store.dispatch("clear_Menus");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("menuList");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("realname");
    if (this.$route.query.token) {
      this.loadingShow = true;
      logins({ token: this.$route.query.token })
        .then((res) => {
          if (res.code === "SUCCESS") {
            sessionStorage.setItem("username", this.ruleForm.userName);
            sessionStorage.setItem("realname", res.data.userName);
            sessionStorage.setItem("userId", res.data.id);
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("companyId", res.data.companyIds);
            var tree = res.data.menuTree;
            let index = tree.subMenuTrees.findIndex((e) => e.url == "gyTravel");
            if (index >= 0) tree.subMenuTrees.splice(index, 1);
            sessionStorage.setItem("menuList", JSON.stringify(tree));
            this.$store.dispatch("addUserInfo", res.data);
            this.$store.dispatch("add_Menus", res.data.menuTree);
            this.$message.success("登录成功");
            this.$router.push(
              "/projectTravel/" +
              tree.subMenuTrees[this.routerForFun(tree.subMenuTrees)]
                .subMenuTrees[0].url
            );
          } else {
            this.$message.error(res.msg);
          }
          this.logining = false;
        })
        .catch((error) => {
          setTimeout(() => {
            if (error.response.data.msg == "该用户暂无权限操作") {
              window.open("about:blank", "_top").close();
            }
            this.logining = false;
          }, 2000);
        });
    }
  },
  mounted () {
    const user = localStorage.getItem("userPwd");
    if (user) {
      this.ruleForm.userName = JSON.parse(user).name;
      this.ruleForm.password = JSON.parse(user).pwd;
      this.checked = true;
    }
    if (this.$route.query.token) {
      document.onkeydown = (event) => {
        var e = event || window.event || arguments.callee.caller.arguments[0];
        if (e && e.keyCode === 13 && this.$route.path === "/login") {
          // enter 键
          this.handleSubmit();
        }
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background: #ffffff;
  #load {
    position: absolute;
    width: 600px;
    height: 36px;
    left: 50%;
    top: 40%;
    margin-left: -300px;
    overflow: visible;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
  }

  #load div {
    position: absolute;
    width: 20px;
    height: 36px;
    opacity: 0;
    font-family: Helvetica, Arial, sans-serif;
    animation: move 2s linear infinite;
    -o-animation: move 2s linear infinite;
    -moz-animation: move 2s linear infinite;
    -webkit-animation: move 2s linear infinite;
    transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    color: #336fee;
    font-size: 22px;
    font-weight: bold;
  }

  #load div:nth-child(2) {
    animation-delay: 0.2s;
    -o-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
  }
  #load div:nth-child(3) {
    animation-delay: 0.4s;
    -o-animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
  }
  #load div:nth-child(4) {
    animation-delay: 0.6s;
    -o-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
  }
  #load div:nth-child(5) {
    animation-delay: 0.8s;
    -o-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s;
  }
  #load div:nth-child(6) {
    animation-delay: 1s;
    -o-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -webkit-animation-delay: 1s;
  }
  #load div:nth-child(7) {
    animation-delay: 1.2s;
    -o-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
  }

  @keyframes move {
    0% {
      left: 0;
      opacity: 0;
    }
    35% {
      left: 41%;
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    65% {
      left: 59%;
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    100% {
      left: 100%;
      -moz-transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg);
      opacity: 0;
    }
  }

  @-moz-keyframes move {
    0% {
      left: 0;
      opacity: 0;
    }
    35% {
      left: 41%;
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    65% {
      left: 59%;
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    100% {
      left: 100%;
      -moz-transform: rotate(-180deg);
      transform: rotate(-180deg);
      opacity: 0;
    }
  }

  @-webkit-keyframes move {
    0% {
      left: 0;
      opacity: 0;
    }
    35% {
      left: 41%;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    65% {
      left: 59%;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    100% {
      left: 100%;
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
      opacity: 0;
    }
  }

  @-o-keyframes move {
    0% {
      left: 0;
      opacity: 0;
    }
    35% {
      left: 41%;
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    65% {
      left: 59%;
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    100% {
      left: 100%;
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg);
      opacity: 0;
    }
  }
}
.login-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #8d5783;
  background: -webkit-linear-gradient(
    left,
    #8d5783,
    #0056fd
  ); /* Safari 5.1-6.0 */
  background: -o-linear-gradient(right, #8d5783, #0056fd); /* Opera 11.1-12.0 */
  background: -moz-linear-gradient(
    right,
    #8d5783,
    #0056fd
  ); /* Firefox 3.6-15 */
  background: linear-gradient(to right, #8d5783, #0056fd); /* 标准语法 */
  position: relative;
  .container-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    @keyframes bounce1 {
      from {
        transform: scale3d(0.84, 0.85, 0.87) translate3d(0, 0, 0);
      }
      to {
        transform: scale3d(0.9, 0.9, 0.9) translate3d(-90px, 30px, -40px);
      }
    }
    @keyframes bounce2 {
      from {
        transform: scale3d(0.9, 0.9, 0.9) translate3d(0, 0, 0);
      }
      to {
        transform: scale3d(0.84, 0.85, 0.87) translate3d(-40px, -40px, 200px);
      }
    }
    @keyframes bounce3 {
      from {
        transform: scale3d(0.84, 0.85, 0.87) translate3d(-40px, -40px, 40px);
      }
      to {
        transform: scale3d(0.8, 0.8, 0.8) translate3d(0, 0, 0);
      }
    }
    .ball1 {
      animation: bounce1 2.5s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
    }
    .ball2 {
      animation: bounce2 2s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
    }
    .ball3 {
      animation: bounce3 3.5s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
    }
    & > p {
      position: relative;
      width: 30%;
      span {
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.1);
        position: absolute;
      }
    }
    & > p:nth-child(1) {
      span {
        width: 35vw;
        height: 35vw;
        top: -10vw;
        left: -2vw;
      }
    }
    & > p:nth-child(2) {
      span {
        width: 30vw;
        height: 30vw;
        top: 5vw;
        left: -13vw;
      }
    }
    & > p:nth-child(3) {
      flex: 1;
      span {
        width: 30vw;
        height: 30vw;
        bottom: -5vw;
        right: -11vw;
      }
    }
  }
  .platform-container {
    width: 80%;
    margin: 0 auto;
    color: #fff;
    z-index: 4;
    position: absolute;
    left: 10%;
    top: 0;
    .header {
      height: 20vh;
      margin-top: 10vh;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      .center {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        section {
          height: 9vh;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          img {
            height: 100%;
          }
        }
        .section {
          height: 9vh;
          margin-left: 22px;
          h3 {
            width: 100%;
            font-size: 2vw;
            font-weight: 400;
            margin: 0;
            margin: 6px 0 12px 0;
          }
          p {
            width: 100%;
            color: #f0f0f0;
            font-size: 0.73vw;
            letter-spacing: 1.11px;
            margin: 0;
          }
        }
      }
    }
    .main {
      height: 60vh;
      .login-page {
        width: 19.2vw;
        text-align: center;
        background: #fff;
        padding: 3.8vh 2.4vw;
        margin: 0 auto;
        position: relative;
        z-index: 99;
        box-shadow: 0 15px 40px -40px #000, 0 -15px 40px -40px #000;
        /deep/ .el-form-item__label {
          font-size: 24px;
          color: #000;
        }
        /deep/.el-checkbox:not(.is-disabled)
          .el-checkbox__input.is-checked
          .el-checkbox__inner {
          background-color: #336ffe;
          border-color: #336ffe;
        }
        .el-button {
          background: #336ffe;
          border-color: #336ffe;
          width: 19.2vw;
        }
        .el-checkbox {
          margin-left: 2px;
          text-align: left;
          width: 19.2vw;
        }
      }
    }
    .footer {
      height: 8vh;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
